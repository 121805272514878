.assessment {
    text-align: left;
    border-radius: 12px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 0.5rem;
    border-bottom: none;
    overflow: hidden;
    border-top: none;
    border: none !important;
    background: #fff;

    .assessment-title {
        font-size: 16px;
        font-weight: 400;
    }
}

.record-icon {
    font-size: 25px;
}

.light-text {
    font-weight: 400;
    color: #bababa;
    font-size: 15px;
}
.assessment-details-page {
    .clipboard-icon {
        font-size: 30px;
        color: #283360;
    }
    .assessment-detail-header {
        font-size: 30px;
        font-weight: 400;
        font-family: "Poppins";
    }
    .subheading {
        font-size: 15px;
    }
    .decription {
        font-size: 20px;
    }
    .my-work-card {
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
        border-radius: 0.5rem;
        overflow: hidden;
    }
    .other-submission-header {
        font-size: 25px;
        font-weight: 400;
        font-family: "Poppins";
    }
}

.about-card-room {
    // box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 2px 6px 2px rgb(60 64 67 / 15%);
    border-radius: 0.5rem;
    overflow: hidden;

    @include laptop {
        min-height: 200px;
    }
}

.assessment-submission-wrapper {
    .user-title {
        font-size: 20px;
        font-family: "Poppins", "sans-serif";
        font-weight: 800;
    }

    .attachments {
        .attachment-header {
            font-size: 18px;
            font-family: "Poppins", "sans-serif";
            font-weight: 800;
        }
    }

    .marks-card {
        background: #32325d;
        color: #fff;
        font-size: 18px;
        font-weight: 700;
    }
}

.assessment-dashboard {
    .ant-layout-sider-zero-width-trigger {
        background: #283360;
        right: -58px;
        top: 20px;
    }
    .ant-menu-light .ant-menu-submenu-active {
        color: #283360;
    }
    .ant-layout-sider {
        @media screen and (min-width: 901px) and (max-width: 1200px) {
            flex: 0 0 235px !important;
            max-width: 230px !important;
            min-width: 230px !important;
            width: 230px !important;
        }
        @media screen and (min-width: 650px) and (max-width: 900px) {
            flex: 0 0 200px !important;
            max-width: 200px !important;
            min-width: 200px !important;
            width: 200px !important;
        }
        @media screen and(min-width: 1200px) {
            flex: 0 0 320px !important;
            max-width: 320px !important;
            min-width: 320px !important;
            width: 320px !important;
        }
        // background: #0e3863;
        // background: rgb(231 231 231);
        background: #fff;
        font-weight: 400;
    }
    .ant-menu-inline {
        border: none !important;
        padding: 5px;
        font-size: 16px;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background: #c6d4ec !important;
        border-radius: 7px;
        height: 45px;
        font-weight: 600;
        color: rgb(28, 27, 27);
    }
    .ant-menu-submenu-selected {
        color: rgb(28, 27, 27);
        background: #eeeeee;
        border-radius: 7px;
    }
    .ant-layout-content {
        margin: 16px !important;
        background: #fff;
        border-radius: 10px;
    }
    .ant-menu-item-active:hover {
        color: #283360;
    }
    .ant-layout {
        background: #eee !important;
    }
    .site-layout-background {
        background: #fff;
        padding: 24px;
        min-height: 360px;
        @include phone {
            padding: 5px;
            margin: 5px;
        }
    }
    .ant-menu-inline .ant-menu-item::after {
        border: none !important;
    }


    .analytics-card {
        border-radius: 10px;
    }

    .ant-input-search-with-button {
        .button {
            background-color: #eee;
            color: black;
        }
    }
    .top-card {
        h5 {
            color: #5f5c5c;
        }
        .list {
            font-size: 18px;
            font-weight: 400;
            color: #6c757d;
        }
    }
}

.round-border {
    border-radius: 12px;
}
.card-shadow {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}
.assessment-feddback-wrapper {
    .comment-body {
        max-height: 450px;
        overflow-y: auto;
    }
    .author-name {
        display: inline;
        margin-right: 0.5rem;
        font-family: "Poppins", "Roboto", Arial, sans-serif;
        font-size: 0.775rem;
        font-weight: 500;
        color: #3c4043;
        line-height: 1.25rem;
        text-decoration: none;
    }
    .date {
        font-family: "Roboto", Arial, sans-serif;
        font-size: 0.68rem;
        font-weight: 400;
        line-height: 0.8rem;
        color: #5f6368;
        display: inline-block;
    }
    .write-content {
        margin: 0 !important;
        background-color: transparent;
        align-items: stretch;
        display: flex;

        &:last-child {
            border-bottom-left-radius: 0.25rem;
            border-bottom-right-radius: 0.25rem;
        }

        .post-compose {
            align-items: flex-start;
            display: flex;
            text-align: inherit;
            width: 100%;
        }

        .post-content {
            flex-basis: auto;
            flex-grow: 1;
            flex-shrink: 1;
            text-align: inherit;

            .compose-actions {
                padding: 0 12px;

                .action {
                    height: 30px;
                    width: 30px;
                    border-radius: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background: #f7f7f7;
                    color: #000;
                    margin: 0 4px;
                    transition: all 0.3s;

                    svg {
                        height: 24px;
                        width: 24px;
                        border-radius: 10px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background: #f7f7f7;
                        margin: 0 4px;
                        transition: all 0.3s;
                    }
                }
            }

            .post-field {
                margin-bottom: 0;

                p {
                    box-sizing: border-box;
                    clear: both;
                    font-size: 1rem;
                    position: relative;
                    text-align: inherit;

                    .text-area {
                        border: 1px solid #e8e8e8;
                        border-bottom: none;
                        border-radius: 0.65rem 0.65rem 0 0;
                        resize: none;
                        font-size: 0.9rem;
                        transition: all 0.3s;
                        box-shadow: none !important;
                        max-width: 100%;
                        min-width: 100%;
                        display: block;
                        width: 100%;
                        color: #363636;
                        background-color: #fff;
                        padding: 10px;

                        &:focus {
                            outline: none !important;
                        }

                        &:focus-visible {
                            outline: none !important;
                        }
                    }
                }
            }

            .actions {
                border-left: 1px solid #e8e8e8;
                border-right: 1px solid #e8e8e8;
                border-bottom: 1px solid #e8e8e8;
                border-radius: 0 0 0.65rem 0.65rem;
            }
        }
    }
    .description {
        font-family: "Robotto", "sans-serif";
        font-size: 0.775rem;
        font-weight: 400;
        line-height: 1.25rem;
        color: #3c4043;
    }
    .description.font-size-small {
        font-size: 0.675rem;
        line-height: 1rem;
    }
}

.answer-comment-post {
    &:focus-visible {
        outline: none !important;
        box-shadow: none !important;
    }
}

.attachments-list {
    .attachment-item {
        border-radius: 3px;
        min-height: 40px;
        overflow: hidden;
        position: relative;
        margin: 2px 0;
        cursor: pointer;
        &:hover {
            background-color: #091e420a;
        }
        .item-preview {
            margin: 0 10px;
            background-color: #091e420a;
            display: block;
            font-size: 18px;
            font-weight: 700;
            height: 100%;
            line-height: 80px;
            text-align: center;
            text-decoration: none;
            width: 100%;
        }
        .item-details {
            .item-name {
                word-wrap: break-word;
                font-weight: 700;
                font-size: 12px;
            }
            .item-other-details {
                font-size: x-small;
            }
        }
    }
}

.assessment--room__collapse {
    .ant-collapse-header {
        align-items: center !important;
    }
}
